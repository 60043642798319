
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="mt-4 mb-5">{{user.employee_name}}</h1>
			<!-- {{user}} -->
			<v-layout row wrap mt-5>
				<!-- <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.language.data.extra.search" outlined></v-text-field> -->
				<v-flex xs12>
                    <h3>
                        {{$store.getters.language.data.fines.title}}:
                    </h3>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers_fine" v-model="selected_rows" :search="search" :items="fine_rows" class="elevation-0" item-key="fine_id">
								<template v-slot:[`item.fine_id`]="{ item }">
									<div>
										<v-btn icon :to="'/fines-list/'+item.fine_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectFines(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.fine_date`]="{ item }">
									{{new Date(item.fine_date).toLocaleString()}}
								</template>
								<template v-slot:[`item.fine_date_insert`]="{ item }">
									{{new Date(item.fine_date_insert).toLocaleString()}}
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteFinesList">{{$store.getters.language.data.fines.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
				<v-layout row wrap mt-5>
				<v-flex xs12>
                    <h3>
                        {{$store.getters.language.data.off_days.title}}:
                    </h3>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers_off_days" v-model="selected_rows" :search="search" :items="off_days_rows" class="elevation-0" item-key="off_day_id">
								
								<template v-slot:[`item.off_day_date`]="{ item }">
									{{new Date(item.off_day_date).toLocaleString()}}
								</template>
								<template v-slot:[`item.off_day_request_date`]="{ item }">
									{{new Date(item.off_day_request_date).toLocaleString()}}
								</template>
								<template v-slot:[`item.off_day_date_answer`]="{ item }">
									{{new Date(item.off_day_date_answer).toLocaleString()}}
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOffDaysList">{{$store.getters.language.data.off_days.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
            <v-layout row wrap mt-5>
				<v-flex xs12>
                    <h3>
                        {{$store.getters.language.data.iligal_days.title}}:
                    </h3>
					<v-card>
						<v-card-text>
							<v-data-table :headers="iligal_days_headers" v-model="selected_rows" :search="search" :items="iligal_days_rows" class="elevation-0" item-key="iligal_day_id">
								
								<template v-slot:[`item.iligal_day_date`]="{ item }">
									<div>
										{{new Date(item.iligal_day_date).toLocaleDateString()}}

									</div>
								</template>
								<template v-slot:[`item.iligal_day_date_insert`]="{ item }">
									<div>
										{{new Date(item.iligal_day_date_insert).toLocaleString()}}
									</div>
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteIligalDaysList">{{$store.getters.language.data.iligal_days.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
            	<v-layout row wrap mt-5>
				<v-flex xs12>
                    <h3>
                        {{$store.getters.language.data.extra_hours.title}}:
                    </h3>
					<v-card>
						<v-card-text>
							<v-data-table :headers="extra_hours_headers" v-model="selected_rows" :search="search" :items="extra_hours_rows" class="elevation-0" item-key="extra_hour_id">
								
								<template v-slot:[`item.extra_hour_date`]="{ item }">
									<div>
										{{new Date(item.extra_hour_date).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.extra_hour_insert_date`]="{ item }">
									<div>
										{{new Date(item.extra_hour_insert_date).toLocaleString()}}
									</div>
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteExtraHoursList">{{$store.getters.language.data.extra_hours.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../requests/employees.request.js'
	export default {
		data() {
			return {
				employees: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				fine_rows: [],
                off_days_rows: [],
                iligal_days_rows: [],
                extra_hours_rows: [],

				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_employees: {},
				delete_dialog: false,
			headers_fine:[

					// {
					// 	text: this.$store.getters.language.data.employees.employee_name,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'employee_name',
					// },
					{
						text: this.$store.getters.language.data.fines.fine_amount,
						align: 'start',
						sortable: true,
						value: 'fine_amount',
					},
					{
						text: this.$store.getters.language.data.fines.fine_date,
						align: 'start',
						sortable: true,
						value: 'fine_date',
					},

					{
						text: this.$store.getters.language.data.fines.fine_note,
						align: 'start',
						sortable: true,
						value: 'fine_note',
					}, {
						text: this.$store.getters.language.data.fines.fine_date_insert,
						align: 'start',
						sortable: true,
						value: 'fine_date_insert',
					},

            ]
            ,
            headers_off_days: [

					// {
					// 	text: this.$store.getters.language.data.employees.employee_name,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'employee_name',
					// },
					{
						text: this.$store.getters.language.data.off_days.off_day_date,
						align: 'start',
						sortable: true,
						value: 'off_day_date',
					},
					{
						text: this.$store.getters.language.data.off_days.off_day_amount,
						align: 'start',
						sortable: true,
						value: 'off_day_amount',
					},
					{
						text: this.$store.getters.language.data.off_days.off_day_request_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_date',
					},
					{
						text: this.$store.getters.language.data.off_days.off_day_note,
						align: 'start',
						sortable: true,
						value: 'off_day_note',
					},
					{
						text: this.$store.getters.language.data.off_days.off_day_date_answer,
						align: 'start',
						sortable: true,
						value: 'off_day_date_answer',
					}

				
				],
			
            
            	iligal_days_headers: [

					// {
					// 	text: this.$store.getters.language.data.employees.employee_name,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'employee_name',
					// },
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_date,
						align: 'start',
						sortable: true,
						value: 'iligal_day_date',
					},
                    {
                        text: this.$store.getters.language.data.iligal_days.iligal_day_amount,
                        align: 'start',
                        sortable: true,
                        value: 'iligal_day_amount',
                    }
                    ,
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_note,
						align: 'start',
						sortable: true,
						value: 'iligal_day_note',
					},
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_date_insert,
						align: 'start',
						sortable: true,
						value: 'iligal_day_date_insert',
					},
				
				],
                	extra_hours_headers: [

					// {
					// 	text: this.$store.getters.language.data.employees.employee_name,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'employee_name',
					// },
					{
						text: this.$store.getters.language.data.extra_hours.extra_hour_hours,
						align: 'start',
						sortable: true,
						value: 'extra_hour_hours',
					},
					{
						text: this.$store.getters.language.data.extra_hours.extra_hour_date,
						align: 'start',
						sortable: true,
						value: 'extra_hour_date',
					},

					{
						text: this.$store.getters.language.data.extra_hours.extra_hour_note,
						align: 'start',
						sortable: true,
						value: 'extra_hour_note',
					},

					{
						text: this.$store.getters.language.data.extra_hours.extra_hour_insert_date,
						align: 'start',
						sortable: true,
						value: 'extra_hour_insert_date',
					}
					
				],}
		},
		computed: {

			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
			this.readEmployees();
		},
		methods: {
            
            

			readEmployees() {
				this.loading = true
				requests.getAllEmployeeDetail(
                    this.$store.getters.user.employee_id
                ).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
                        this.fine_rows = r.data.fines
                        this.off_days_rows = r.data.off_days
                        this.iligal_days_rows = r.data.iligal_days
                        this.extra_hours_rows = r.data.extra_hours
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
		
		},
	}
</script>
                    